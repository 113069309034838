import React from "react";
import styles from "./styles";
import injectSheet from "react-jss";
import HeaderAfrica from "./HeaderAfrica";
import pic from "./image/a-viertel-2.jpg";

const WeddingBars = ({ classes }) => (
  <div className={classes.background}>
    <HeaderAfrica />
    <form action="">
      <label>id</label>
      <input type="text" />
      <label>name</label>
      <input type="text" />
      <label>subtitle</label>
      <input type="text" />
    </form>
    <button>Submit</button>
    <div id="appendRestaurantsHere" />
  </div>
);

let restaurants = [
  {
    id: "LuLa",
    name: "LuLa am Markt",
    subtitle: "Café",
    img:
      "https://lh5.googleusercontent.com/p/AF1QipMHUGKUTwadfNz-tuMr4OSbuH4ng5IKrqQoLk7w=w284-h160-k-no",
    address: "Lauterstraße 14, 12159 Berlin",
    url: "http://lula-berlin.de",
    tel: "030 12345678",
    location: ["52.4723157", "13.3350244"]
  }
];

document.addEventListener("DOMContentLoaded", () => {
  const b = document.getElementById("appendRestaurantsHere");
  console.log("restaurants", restaurants);
  for (var i = 0; i < restaurants.length; i++) {
    const restaurant = restaurants[i];
    const htmlRestaurant = appendRestaurantElement(restaurant);
    b.appendChild(htmlRestaurant);
  }
});

const appendRestaurantElement = ({ id, name, subtitle }) => {
  console.log(id);
  const div = document.createElement("div");
  div.innerHTML = "id " + id;
  const innerDiv = document.createElement("div");
  innerDiv.style.display = "block";
  innerDiv.innerHTML = "name " + name + "\n" + "subtitle " + subtitle;
  div.appendChild(innerDiv);
  return div;
};

export default injectSheet(styles)(WeddingBars);
